import axios from "axios";
import { getFromStorage, renderErrorMessage, renderSuccessMessage } from "../assets/functions";


const authApi = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
    },
    withCredentials: true,
});

authApi.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response.status === 401) {
            renderErrorMessage(error.response.data.message).then(() => {
                localStorage.clear();
                window.location.reload();
            });
        } else if (error.response.status === 503) {
            renderSuccessMessage(error.response.data.message);
        } else {
            renderErrorMessage(error.response.data.message);
            throw new Error(error.response.data.message, {
                cause: error.response.status,
            });
        }
    }
);


export const signUp = (data) => {
    return api.post("journalist/register", data);
};
export const InputPasswords = (data) => {

    return api.patch("journalist/create-profile", data);
};
export const getQuestions = () => {
    return api.get("questions");
};
export const ResetEmailAdd = (data) => {
    return api.post("journalist/reset-password", data);
};
// export const SecurityCheck = (data) => {
//     return api.post("journalist/security-question-check", data);
// };
export const VerifyOTP = (data) => {
    return api.post("journalist/verify-otp", data);
};
export const NewCredentials = (data) => {
    return api.patch("journalist/create-new-password", data);
};
export const signIn = (data) => {
    return api.post("admin/login", data);
};


const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_LOCAL_BASEURL}`
            : `${process.env.REACT_APP_PROD_BASEURL}`,
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Credentials": true,
    },
    // withCredentials: true,
});


api.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
    return config;
}, null);



api.interceptors.response.use(
    (response) => response.data,

    (error) => {
        if (error.response.status === 401) {
            renderErrorMessage(error.response.data.message).then(() => {
                localStorage.clear();
                window.location.reload();
            });
        } else if (error.response.status === 503) {
            renderSuccessMessage(error.response.data.message);
            throw new Error(error.response.data.message ?? "Account Created");
        } else {
            renderErrorMessage(error.response.data.message);
            throw new Error(error.response.data.message, {
                cause: error.response.status,
            });
        }
    }
);



export const createQuery = (data) => {
    return api.post("journalist/queries", data);
};

export const archiveQuery = (id) => {
    return api.patch(`/journalist/queries/${id}/archive`);
};

export const editQuery = ({ id, ...data }) => {
    return api.patch(`journalist/queries/${id}`, data);
};

export const getNotification = (page) => {
    return api.get(`journalist/notifications?page=${page}`);
};

export const createAndUpdateProfile = (data) => {
    return api.post("journalist/update-profile", { _method: "PATCH", ...data }, { headers: { "Content-Type": "multipart/form-data" } });
};



export const getAssociatedOutlets = () => {
    return api.get("associated-outlets");
};




export const getAllQueries = (searchQuery, page) => {
    return api.get(`journalist/queries?q=${searchQuery}&page=${page}`);
};

export const getArchivesQueries = (page) => {
    return api.get(`journalist/archives?page=${page}`);
};


// export const QueryResponse = (id) => {
//     return api.get(`/journalist/queries/${id}/pitch/:pitchId`);
// };



export const getUserProfile = (id, role, userRole) => {
    return api.get(`profile-search/${id}?role=${role}&userRole=${userRole}`);
};

export const readNotification = (notificationId) => {

    return api.patch(`journalist/notifications/${notificationId}`);

};


export const ReplyPitch = ({ queryId, pitchId, replyText }) => {
    return api.post(`journalist/queries/${queryId}/pitch/${pitchId}/reply`, { reply: replyText });
};
export const changePassword = (data) => {
    return api.patch("journalist/change-password", data);
};
export const getMyLeaks = () => {
    return api.get(`journalist/leaks`);
};
export const getMyLeakDetails = (id, page) => {
    return api.get(`journalist/leaks/${id}?page=${page}`);
};
export const ReplyLeak = ({ leakId, ...data }) => {
    return api.post(`journalist/leaks/${leakId}/reply`, data);
};
export const PostLeakReply = ({ leakId, ...data }) => {
    return api.post(`journalist/leaks/${leakId}/reply`, data);
};
export const CreateTag = (data) => {
    return api.post(`tags`, data);
};

export const DeleteTag = (id) => {
    return api.delete(`tags/${id}`);
};
export const getTags = () => {
    return api.get("tags");
};
export const getOutlet = () => {
    return api.get("associated-outlets");
};
export const CreateOutlet = (data) => {
    return api.post(`associated-outlets`, data);
};
export const DeleteOutlet = (id) => {
    return api.delete(`associated-outlets/${id}`);
};
export const getAllJournalist = (params) => {
    return api.get(`journalists`, { params });
};
export const SuspendJournalist = ({ journalist, ...data }) => {
    return api.post(`admin/journalists/${journalist}/disable`, data);
};
export const UnsuspendJournalist = ({ journalist, ...data }) => {
    return api.post(`admin/journalists/${journalist}/enable`, data);
};

export const SuspendSource = ({ expert, ...data }) => {
    return api.post(`admin/sources/${expert}/disable`, data);
};
export const UnsuspendSource = ({ expert, ...data }) => {
    return api.post(`admin/sources/${expert}/enable`, data);
};
export const getAllSource = (params) => {
    return api.get("sources", { params });
};

export const VerifyJournalist = ({ journalist, ...data }) => {
    return api.post(`admin/verify-journalist/${journalist}`, data);
};

export const UnverifyJournalist = ({ journalist, ...data }) => {
    return api.post(`admin/unverify-journalist/${journalist}`, data);
};
export const profileSearch = (searchQuery, page) => {
    return api.get(`profile-search?q=${searchQuery}&role=${"journalist"}&page=${page}`);
};

export const getAllAdmin = (params) => {
    return api.get("admin/get", { params });
};

export const CreateAdmin = (data) => {
    return api.post(`admin/register`, data);
};

export const getAlltheQueries = (params) => {
    return api.get("admin/queries", { params });
};
export const getQueryDetails = (id, page) => {
    return api.get(`/admin/queries/${id}?page=${page}`);
};
export const getPitchDetails = (id, page) => {
    return api.get(`/admin/queries/${id}/pitch?page=${page}`);
};
export const getPitchReply = (pitchid, page) => {
    return api.get(`/admin/pitch/${pitchid}/replies?page=${page}`);
};
export const DeletePitch = (id) => {
    return api.delete(`admin/pitch/${id}`);
};
export const DeletePitchReply = (id) => {
    return api.delete(`admin/pitch-reply/${id}`);
};
// export const DeleteQuery = (uuid) => {
//     return api.delete(`admin/query/${uuid}`);
// };
export const getAlltheLeaks = (params) => {
    return api.get("admin/leaks", { params });
};
export const DeleteLeak = (uuid) => {
    return api.delete(`admin/leak/${uuid}`);
};
export const getAllLeakDetails = (id, page) => {
    return api.get(`/admin/leaks/${id}?page=${page}`);
};